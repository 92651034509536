import { React, useState, useEffect, memo } from "react";
import useWindowDimensions from "../../ScreenSize";
import axios from "axios";
import { ServerAddress } from "../../../../constants/ServerAddress";
import { useSelector } from "react-redux";

const TimeLine = ({ avatarBg, avatarSrc, title, text, author, date }) => {
  // To get Screen Size
  const { height, width } = useWindowDimensions();
  const accessToken = useSelector((state) => state.authentication.access_token);

  const [birthday_List, setbirthday_List] = useState([]);
  const get_birthday = () => {
    let final = [];

    axios
      .get(ServerAddress + `ems/get_user_birthday/?p=1`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        let pair = response.data.results;
        pair.forEach((item) => {
          final.push({
            Date: item.date_of_birth,
            profile: item.profile_pic,
          });
        });
        setbirthday_List(final);
      })
      .catch((error) => {
        console.log(`Error while in get data,${error}`);
      });
  };

  useEffect(() => {
    get_birthday();
  }, []);
  return (
    <li className="list-group-item border-0" style={{ marginBottom: "5px", padding: "4px" }}>
      <div className="d-flex" style={{ borderRadius: "4px", boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em' }}>

        <div className={`avatar-xs me-3 rounded-circle`} style={{ border: "2px solid #F6F5F2", padding: "2px", boxShadow: ' rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px' }}>
          <span style={{ borderRadius: "20px" }} className="avatar-title">
            <img
              src={avatarSrc}
              alt="User_Image"
              height="32"
              width="32"
              style={{ borderRadius: "20px", border: "2px solid black" }}
            />
          </span>
        </div>

        <div className="flex-grow-1">
          <h5 className="font-size-14">{title}</h5>
          <p className="text-muted">{text}</p>
          <div className="float-end">
            <p className="text-muted mb-0">
              <i className="mdi mdi-account me-1" /> {author}
            </p>
          </div>
          <p className="text-muted mb-0">{date}</p>
        </div>


      </div>
    </li>
  );
};
export default memo(TimeLine);
