import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  e_access_token: "",
  orgs: [],
  business_access_token: "",
  subscription: {},
  eway_info: {},
  // userpermission: [],
};

export const EwaySlice = createSlice({
  name: "eway",
  initialState,
  reducers: {
    setEAccessToken: (state, action) => {
      state.e_access_token = action.payload;
    },
    setOrgs: (state, action) => {
      state.orgs = action.payload;
    },
    setBusinesssAccessToken: (state, action) => {
      state.business_access_token = action.payload;
    },
    setSubrcption: (state, action) => {
      state.subscription = action.payload;
    },
    setEway_info: (state, action) => {
      state.eway_info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEAccessToken,
  setSubrcption,
  setOrgs,
  setBusinesssAccessToken,
  setEway_info,
} = EwaySlice.actions;

export default EwaySlice.reducer;
