import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const StackedBar = ({ data }) => {
  return (
    <>
      <div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              //   angle={-45} textAnchor="end"
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="pv"
              stackId="a"
              fill="#3854AA"
              label={{ position: "top" }}
            />
            {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
export default StackedBar;
