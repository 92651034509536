import { combineReducers } from "redux";
import authenticationReducer from "./authentication/Authentication";
import permissionsReducer from "./permissions/Permissions";
import paginationReducer from "./pagination/Pagination";
import alertReducer from "./alert/Alert";
import parentFilterReducer from "./parentFilter/ParentFilter";
import filterValueReducer from "./filterValue/FilterValue";
import searchBarReducer from "./searchBar/SearchBar";
import dataListReducer from "./dataList/DataList";
import clientReducer from "./master/client/Client";
import customerReducer from "./master/customer/Customer";
import orderReducer from "./booking/order/Order";
import EwayBillReducer from "./ewayBill/EwayBill";
import UserDocReducer from "./DocumentVerification/DocumentVerification"

const appReducer = combineReducers({
  authentication: authenticationReducer,
  alert: alertReducer,
  pagination: paginationReducer,
  permissions: permissionsReducer,
  parentfilter: parentFilterReducer,
  filtervalue: filterValueReducer,
  searchbar: searchBarReducer,
  datalist: dataListReducer,
  client: clientReducer,
  customer: customerReducer,
  order: orderReducer,
  e_wayBill: EwayBillReducer,
  userDocs : UserDocReducer

});

export default appReducer;
