import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {ServerAddress} from "../../../constants/ServerAddress";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Compressor from "compressorjs";
 // import { Link } from "react-scroll";

const UserDetailsForm = () => {

    const[addressb , setaddressb] = useState(false)
    const [bankNameb, setbankNameb] = useState(false)
    const [branchNameb, setbranchNameb] = useState(false)
    const [ifscb, setifscb] = useState(false)
    const [accntb, setaccntb] = useState(false)
    const [imageErr, setimageErr] = useState(false)
    const [pdfError, setpdfError] = useState(false)
    const [pdfEduErr, setpdfEduErr] = useState(false)
    const [resumeErr, setresumeErr] = useState(false)
    // const [fnameErr, setfnameErr] = useState(false)
    // const [lnameErr, setlnameErr] = useState(false)
    // const documentId = useSelector((state) => state.authentication.document_id);
    
   
    const [image, setImage] = useState(null);
    const[bankImage , setBankImage] = useState();
    const [pdf, setPdf] = useState(null);
    const [pdfEdu, setPdfEdu] = useState(null);
    const [pdfPaySlip, setPdfPaySlip] = useState(null);
    const [pdfExp, setPdfExp] = useState(null);
    const [pdfResume, setPdfResume] = useState(null);
    // const [fname, setfname] = useState("");
    // const [lname, setlname] = useState("");
    // const [email, setemail] = useState("")
    // const [phone, setphone] = useState(0)
    const[permanentAddress,setPermanentAddress] = useState("");
    const [bankname, setbankname] = useState("")
    const [ifsc, setifsc] = useState("")
    const [accNum, setaccNum] = useState("")
    const [branch, setbranch] = useState("")
    const [banknameErr, setbanknameErr] = useState(false)
    const [ifscErr, setifscErr] = useState(false)
    const [accNumErr, setaccNumErr] = useState(false)
    const [branchErr, setbranchErr] = useState(false)
    const [emailErr, setemailErr] = useState(false)
    const[phoneErr, setPhoneErr] = useState(false)
   
    const [addressErr, setaddressErr] = useState(false)

    const showToastMessage = () => {
      toast.success("Details submitted successfully !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
      });
    };

    useEffect(()=>{
        if(image){setimageErr(false)}
        if(pdf){setpdfError(false)}
        if(pdfEdu){setpdfEduErr(false)}
        if(pdfResume){setresumeErr(false)}
        // if(fname !==""){setfnameErr(false)}
        // if(lname !==""){setlnameErr(false)}
        if(branch){setbranchErr(false)}
        if(bankname){setbanknameErr(false)}
        if(ifsc){setifscErr(false)}
        if(accNum){setaccNumErr(false)}
        // if(email){setemailErr(false)}
        // if(phone){setPhoneErr(false)}
        if(permanentAddress){setaddressErr(false)}
    },[image, pdf, pdfEdu, pdfResume,branch,bankname,ifsc,accNum,permanentAddress])
    

    // const handleFileChange = (event) => {
    //   const file = event?.target?.files[0];
      
    //   if (file && file.size > 300 * 1024) {
    //     alert("Image Size is Greater than 300kb");
  
    //     new Compressor(file, {
         
  
    //       quality: 0.9,
    //       maxWidth: file.width * 0.9,
    //       maxHeight: file.height * 0.9,
    //       success(result) {
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //           const base64Url = reader.result;
    //           console.log(base64Url);
    //           console.log("Compressed image size:", result.size);
    //           setuser_image(base64Url);
    //           console.log(user_image);
    //         };
  
    //         reader.readAsDataURL(result);
    //       },
    //       error(err) {
    //         console.error("Image compression error:", err);
    //       },
    //     });
    //   } else {
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       const base64Url = reader.result;
    //       console.log(base64Url);
    //       setuser_image(base64Url);
    //       console.log(user_image);
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

          if (file && file.size > 300 * 1024) {
        alert("Image Size is Greater than 300kb");
  
        new Compressor(file, {
         
  
          quality: 0.9,
          maxWidth: file.width * 0.9,
          maxHeight: file.height * 0.9,
          success(result) {
            const reader = new FileReader();
            reader.onload = () => {
              const base64Url = reader.result;
              console.log(base64Url);
              console.log("Compressed image size:", result.size);
              setImage(base64Url);
              console.log(image);
            };
  
            reader.readAsDataURL(result);
          },
          error(err) {
            console.error("Image compression error:", err);
          },
        })}
        else{
          const reader = new FileReader();

          reader.onloadend = () => {
              // Convert image to base64 URL
              const base64String = reader.result;
              setImage(base64String);
          };
  
          if (file) {
              reader.readAsDataURL(file);
          }
      }
        }

      

   
    const handleBankImageChange = (e) => {
        const file = e.target.files[0];

        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setBankImage(base64Url);
                console.log(bankImage);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

            reader.onloadend = () => {
                // Convert image to base64 URL
                const base64String = reader.result;
                setBankImage(base64String);
            };
    
            if (file) {
                reader.readAsDataURL(file);
            }
          }
       
    }
 

    const handlePdfChange = (e) => {
        const file = e.target.files[0];

        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setPdf(base64Url);
                console.log(pdf);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

            reader.onloadend = () => {
                // Convert PDF to base64 URL
                const base64String = reader.result;
                setPdf(base64String);
            };
    
            if (file) {
                reader.readAsDataURL(file);
            }
          }

        

        
       
    };

   

    const handleEduPdfChange = (e) => {
        const file = e.target.files[0];

        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setPdfEdu(base64Url);
                console.log(pdfEdu);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

            reader.onloadend = () => {
                // Convert PDF to base64 URL
                const base64String = reader.result;
                setPdfEdu(base64String);
            };
    
            if (file) {
                reader.readAsDataURL(file);
            }
          }



       

        
    };
   

    const handleExpPdfChange = (e) => {
        const file = e.target.files[0];


        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setPdfExp(base64Url);
                console.log(pdfExp);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

        reader.onloadend = () => {
            // Convert PDF to base64 URL
            const base64String = reader.result;
            setPdfExp(base64String);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
          }

        

        
    };
 

    const handlePaySlipPdfChange = (e) => {
        const file = e.target.files[0];

        
        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setPdfPaySlip(base64Url);
                console.log(pdfPaySlip);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

            reader.onloadend = () => {
                // Convert PDF to base64 URL
                const base64String = reader.result;
                setPdfPaySlip(base64String);
            };
    
            if (file) {
                reader.readAsDataURL(file);
            }
          }


        

       

        
    };
  

    const handleResumePdfChange= (e) => {
        const file = e.target.files[0];


        if (file && file.size > 300 * 1024) {
          alert("Image Size is Greater than 300kb");
    
          new Compressor(file, {
           
    
            quality: 0.9,
            maxWidth: file.width * 0.9,
            maxHeight: file.height * 0.9,
            success(result) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64Url = reader.result;
                console.log(base64Url);
                console.log("Compressed image size:", result.size);
                setPdfResume(base64Url);
                console.log(pdfResume);
              };
    
              reader.readAsDataURL(result);
            },
            error(err) {
              console.error("Image compression error:", err);
            },
          })}
          else{
            const reader = new FileReader();

            reader.onloadend = () => {
                // Convert PDF to base64 URL
                const base64String = reader.result;
                setPdfResume(base64String);
            };
    
            if (file) {
                reader.readAsDataURL(file);
            }
          }

       

        
    };




        const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
        const id_is = currentUrl.split("=")[1];
        console.log("current url is:", currentUrl);
        console.log("Current url after split", id_is);


        // useEffect(() => {
        //   if(id_is) {
        //     get_user(id_is);
        //   }
          
        // }, [id_is])
        
        // const get_user = (id) => {
        //   axios
        //     .get(
        //       ServerAddress +
        //         `url` + id,
              
        //     )
        //     .then((response) => {
        //      if(response.data) {
        //       alert("User get ");

        //      }
        //     })
        //     .catch((err) => {
        //       alert(`Error Occur in Get , ${err}`);
        //     });
        // };

        const Update_user = (id) => {
          axios
            .put(
              ServerAddress + "ems/submit_document/" + id,
              {
               image: image,pdf: pdf,pdfEdu: pdfEdu,pdfResume:pdfResume, branch:branch, bankname:bankname,ifsc:ifsc,accNum:accNum,permanentAddress:permanentAddress,bankImage:bankImage,pdfExp:pdfExp,pdfPaySlip:pdfPaySlip
              }
            )
            .then(function (response) {
              if (response.data.status === "success") {
               alert("Form Submited successfully")
               showToastMessage()
              }
            })
            .catch(function (err) {
              alert(`rror While  Updateing Coloader ${err}`);
            });
        };

  return (
    <div className='bg-gray-200 min-h-screen  font-semibold'>
      <h1 className='text-4xl pt-10 text-center' id="title">USER DETAILS FORM</h1>
      <div className='flex flex-col justify-center items-center mt-8 border-black border-2 mx-2'>
      <p>Please enter all the details mentioned below carefully , the fields marked with * are mandotry and should all be provided for further steps of onboarding , Kindly upload the documents having good clarity otherwise the process will be annuled.</p>
      <p className='text-left'>Best Regards, Etechcube</p>
      </div>
     
      <form onSubmit={(e)=>{
        e.preventDefault();
        // if(fname===""){
        //   setfnameErr(true);
             
        // }else if(lname=== ""){
        //     setlnameErr(true);
        //   }
        //   else if(email=== ""){
        //     setemailErr(true);
        //   }
        //   else if(phone=== 0){
        //     setPhoneErr(true);
        //   }
           if(image=== null){
            setimageErr(true);
          }
          else if(permanentAddress=== ""){
            setaddressErr(true);
          }
          else if(pdf=== null){
            setpdfError(true);
          }
          else if(bankname=== ""){
            setbanknameErr(true);
          }
          else if(branch=== ""){
            setbranchErr(true);
          }
          else if(ifsc=== ""){
            setifscErr(true);
          }
          else if(accNum=== ""){
            setaccNumErr(true);
          }
          
          
          else if(pdfEdu=== null){
            setpdfEduErr(true);
          }
          else if(pdfResume=== null){
            setresumeErr(true);
          }
          else{Update_user(id_is)
            showToastMessage()
          }
        
      }}>
        <div className='flex flex-col justify-center items-center gap-10 mt-10 '>
          <ToastContainer/>
            {/* <div className='flex gap-6 '>
            <div className="flex flex-col gap-2">
            <label htmlFor="inviteeName" className='text-left'>Enter First Name</label>
            <input disabled={true} type="text" id="inviteeName" className='border border-black w-96 p-1' onChange={(e)=>setfname(e.target.value)}></input>
            {fnameErr&&<label className='text-red-800 text-left'>Please enter your first name</label>}
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="inviteeName" className='text-left'>Enter Last Name</label>
            <input disabled={true} type="text" id="inviteeName" className='border border-black w-96 p-1' onChange={(e)=>setlname(e.target.value)}></input>
            {lnameErr&&<label className='text-red-800 text-left'>Please enter your last name</label>}
          </div> */}
          {/* <div className="flex flex-col gap-2">
            <label htmlFor="inviteeEmail" className='text-left'>Enter Email</label>
            <input disabled={true} type="text" id="inviteeEmail" className='border border-black w-96 p-1'onChange={(e)=>setemail(e.target.value)}></input>
            {emailErr&&<label className='text-red-800 text-left'>Please enter your email</label>}
          </div> */}
            {/* </div>

            <div className='flex gap-6'>
            <div className="flex flex-col gap-2">
            <label htmlFor="inviteeEmail" className='text-left'>Enter Email</label>
            <input disabled={true} type="text" id="inviteeEmail" className='border border-black w-96 p-1'onChange={(e)=>setemail(e.target.value)}></input>
            {emailErr&&<label className='text-red-800 text-left'>Please enter your email</label>}
          </div>
            <div className="flex flex-col gap-2">
            <label htmlFor="inviteePhoneNumber" className='text-left'>Enter Phone Number</label>
            <input disabled={true} type="text" id="inviteePhoneNumber" className='border border-black w-96 p-1'onChange={(e)=>setphone(e.target.value)}></input>
            {phoneErr&&<label className='text-red-800 text-left'>Please enter your phone number</label>}
          </div>
           */}
          
          {/* <div className='flex flex-col ml-20   gap-3 '>
                    
                    <label htmlFor="image" className='text-left'>Upload Your Passport Size photo*</label>
                    <input type="file" id="image" accept="image/*" onChange={(e)=>handleImageChange(e)} />
                    {imageErr && <label className='text-left text-red-800'>Please select a photo</label>}
                
            </div> */}
            {/* </div> */}

            <div className='flex flex-col   gap-3 '>
                    
                    <label htmlFor="image" className='text-left'>Upload Your Passport Size photo*</label>
                    <input type="file" id="image" accept="image/*" onChange={(e)=>handleImageChange(e)} />
                    {imageErr && <label className='text-left text-red-800'>Please select a photo</label>}
                
            </div>

            
            <div className='flex items-center justify-center gap-2'>
            <div className="flex flex-col gap-2">
            <label htmlFor="inviteeAddress" className=''>Enter Permanent Adress*</label>
            <textarea type="text" id="inviteeAddress" className={`border-2 border-gray-600 w-96 p-1  ${addressb ? "shadow-xl shadow-blue-800" : ""} ${addressErr ? " shadow-xl shadow-red-800 " : ""}`}onChange={(e)=>setPermanentAddress(e.target.value)} onFocus={()=>setaddressb(true)} onBlur={()=>setaddressb(false)}></textarea>
            {addressErr && <label className={`text-red-800 text-left `}>Please enter your address</label>}
            
          </div>
            <div className='flex flex-col   gap-3 ml-28 '>
                    
                    <label htmlFor="image" className='text-left'>Proof of Address:Aadhaar card with complete address*</label> 
                    <input type="file" id="pdf" accept=".pdf" onChange={(e)=>handlePdfChange(e)} />
                    {pdfError && <label className='text-left text-red-800'>Please select a pdf file</label>}
                
            </div>
            </div>

            
        
          
            
            <div className="flex flex-col gap-4">
             
            <label htmlFor="inviteeBankDetails" className='text-center text-xl mb-4'>Enter Bank Details* </label>
            
            <div className='flex gap-10 items-center'>
            <label className='text-left w-56'>Enter Name Associated with Bank Account</label>
            <input type="text" id="inviteeAddress" className={`border-2 border-gray-600 w-96 p-1  ${bankNameb ? "shadow-xl shadow-blue-800" : ""} ${banknameErr ? " shadow-xl shadow-red-800 " : ""}`} onChange={(e)=>setbankname(e.target.value)} onFocus={()=>setbankNameb(true)} onBlur={()=>setbankNameb(false)}></input>
            </div>
            <div  className='flex gap-10 items-center'> <label className='text-left w-56'>Enter Bank Branch Name</label>
            <input type="text" id="inviteeAddress" className={`border-2 border-gray-600 w-96 p-1  ${branchNameb ? "shadow-xl shadow-blue-800" : ""} ${branchErr ? " shadow-xl shadow-red-800 " : ""}`} onChange={(e)=>setbranch(e.target.value)} onFocus={()=>setbranchNameb(true)} onBlur={()=>setbranchNameb(false)}></input></div>
            <div  className='flex gap-10 items-center'><label className='text-left w-56'>Enter IFSC Code</label>
            <input type="text" id="inviteeAddress" className={`border-2 border-gray-600 w-96 p-1  ${ifscb ? "shadow-xl shadow-blue-800" : ""} ${ifscErr ? " shadow-xl shadow-red-800 " : ""}`} onChange={(e)=>setifsc(e.target.value)} onFocus={()=>setifscb(true)} onBlur={()=>setifscb(false)}></input></div>
            <div  className='flex gap-10 items-center'>
            <label className='text-left w-56'>Enter Account Number</label>
            <input type="text" id="inviteeAddress" className={`border-2 border-gray-600 w-96 p-1  ${accntb ? "shadow-xl shadow-blue-800" : ""} ${accNumErr ? " shadow-xl shadow-red-800 " : ""}`} onChange={(e)=>setaccNum(e.target.value)} onFocus={()=>setaccntb(true)} onBlur={()=>setaccntb(false)}></input>
            </div>
            {(branchErr || banknameErr || ifscErr || accNumErr) && <label className='text-red-800 text-center'>Please enter all the bank details correctly</label>}
            
           
            
           
            
           
            
          </div>
          <div className='flex'>
          <div className='flex flex-col   w-[500px] gap-3 ml-28 '>
                    
                    <label htmlFor="image" className='text-left'>Bank Account Verification: A copy of a canceled cheque or a bank statement showing your account details.(Optional)</label>
                    <input type="file" id="image" accept="image/*" onChange={(e)=>handleBankImageChange(e)} />
                
            </div>
            <div className='flex flex-col   w-[500px] gap-3 ml-28 '>
                    
                    <label htmlFor="image" className='text-left'>Latest Academic Qualification Certificate*</label> 
                    <input type="file" id="pdf" accept=".pdf" onChange={(e)=>handleEduPdfChange(e)} />
                    {pdfEduErr&& <label className='text-left text-red-800'>Please select a pdf file</label>}
                
            </div>
          </div>
          <div className='flex'>
          <div className='flex flex-col   w-[500px] gap-3 ml-28 '>
                    
                    <label htmlFor="image" className='text-left'>Experience Certificate(Optional)</label> 
                    <input type="file" id="pdf" accept=".pdf" onChange={(e)=>handleExpPdfChange(e)} />
                
            </div>
            <div className='flex flex-col   w-[500px] gap-3 ml-28 '>
                    
                    <label htmlFor="image" className='text-left'>Last Pay Slip(Optional)</label> 
                    <input type="file" id="pdf" accept=".pdf" onChange={(e)=>handlePaySlipPdfChange(e)} />
                
            </div>
          </div>
           
           
            <div className='flex flex-col   gap-3  '>
                    
                    <label htmlFor="pdf" className='text-left'>Updated Resume*</label> 
                    <input type="file" id="pdf" accept=".pdf" onChange={(e)=>handleResumePdfChange(e)} />
                    {resumeErr && <label className='text-left text-red-800'>Please select a pdf file</label>}
                
            </div>
            <button type='submit' className='bg-blue-950 p-2  px-8 rounded-lg text-white mb-4 hover:scale-110 duration-150 '>Submit</button>
        </div>
     
               
      
      </form>
    </div>
  );
}

export default UserDetailsForm;
