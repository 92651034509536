import React, { useEffect, useState } from 'react'
import './NewDasboard.css'
import TopCards from './TopCards'
import { Col, Modal, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import DashboardTitle from '../../data/dashboard/DashboardTitle';
import DashBoardData from '../../data/dashboard/DashBoardData';
import { useSelector } from 'react-redux';
import DataList from "../../components/listDisplay/dataList/DataList";
import NumPagination from '../../components/listDisplay/numPagination/NumPagination';
import DashboardUsertitle from '../../data/dashboard/DashboardUsertitle';
import DashboardUserData from '../../data/dashboard/DashboardUserData';
import IssueCards from './IssueCards';
import StackedBar from '../../components/dashboard/charts/StackedBar';
import DashboardTimelineSection from './DashboardTimelineSection';
import { ServerAddress } from '../../constants/ServerAddress';
import axios from 'axios';
import toTitleCase from '../../lib/titleCase/TitleCase';


const NewDasboard = ({ greeting,setcounter,counter }) => {
    const location = useLocation();
    const AccessToken = useSelector((state) => state.authentication.access_token);
    const data_len = useSelector((state) => state.pagination.data_length);
    const page_num = useSelector((state) => state.pagination.page_number);
    const search = useSelector((state) => state.searchbar.search_item);
    const userdetails = useSelector((state) => state.authentication.userdetails);

    console.log("userdetails==", userdetails)

    // modal related information
    const [showM, setShowM] = useState(false);
    const handleCloseM = () => setShowM(false);
    const handleOpenM = () => setShowM(true);

    const [showM2, setShowM2] = useState(false);
    const handleCloseM2 = () => setShowM2(false);
    const handleOpenM2 = () => setShowM2(true);
    const [today_data, settoday_data] = useState('')

    // -------------------------------------dashboard data 
    const [total_issue, settotal_issue] = useState(0)
    const [totalsolved_issue, settotalsolved_issue] = useState(0)
    const [total_pending_issue, settotal_pending_issue] = useState(0)
    const [total_project, settotal_project] = useState(0)
    const [total_user, settotal_user] = useState(0)
    const [total_attendance, settotal_attendance] = useState(0)
    const [total_absence, settotal_absence] = useState(0)
    const [leave_data, setleave_data] = useState(0)
    const [holiday_data, setholiday_data] = useState()

    console.log("holiday_data==", holiday_data)

    const [stackedbar, setstackedbar] = useState([
        {
            name: "Page A",
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: "Page B",
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: "Page C",
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: "Page D",
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: "Page E",
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: "Page F",
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: "Page G",
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ]);


    const dashboard = async () => {
        try {
            const response = await axios.get(
                ServerAddress +
                `ems/user_dashboard/?p=${1}&records=${10}&name_search=${'org_search_item'}&user_id=${'user_id'}`,
                {
                    headers: { Authorization: `Bearer ${AccessToken}` },
                },
            );

            if (response) {
                settotal_issue(response.data[0].total_issue)
                settotalsolved_issue(response.data[0].total_resolved)
                settotal_pending_issue(response.data[0].total_pending)
                settotal_project(response.data[0].total_project)
                settotal_user(response.data[0].user_data)
                settotal_user(response.data[0].user_data)
                settotal_attendance(response.data[0].attendance_data)
                settotal_absence(response.data[0].total_absence)
                setleave_data(response.data[0].leave_data)
                setcounter(response.data[0].holiday_data)
            }

        } catch (err) {
            console.log(`Error Occur in Get, ${err}`);
        }
    }



    
    useEffect(() => {
        if (AccessToken) {
            dashboard()
        }
    }, [])


    return (

        <div className='NewDasboard-container'>

            <Modal show={showM} onHide={handleCloseM} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Today's Attendance </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <DataList
                            Data_Title={DashboardTitle}
                            Data_Format={DashBoardData}

                            path={`ems/dashboardattendance/?search=${search}&p=${page_num}&records=${data_len}&type=${today_data}`}
                            checkbox='false'
                        />
                        <NumPagination path={"path"} />
                    </div>

                </Modal.Body>
            </Modal>

            <Modal show={showM2} onHide={handleCloseM2} className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Today's {today_data} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <DataList
                            Data_Title={DashboardUsertitle}
                            Data_Format={DashboardUserData}
                            path={`ems/dashboardattendance/?search=${search}&p=${page_num}&records=${data_len}&type=${today_data}`}
                            checkbox='false'
                        />
                        <NumPagination path={"path"} />
                    </div>

                </Modal.Body>
            </Modal>


            <div className='main-inside'>
                {/* ---------main---------- */}
                <div className='NewDasboard-left'>
                    {/*---- profile ----- */}
                    <div className='dash-profile tw-mb-2'>
                        <div style={{ width: "60px", height: "100%", boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset', padding: "2px", borderRadius: "4px" }}>
                            {/* profile image here */}
                            <img
                                src={userdetails?.profile_pic}
                                alt='no Profile Pic'
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>



                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start", height: "100%", padding: "0 16px" }}>
                            <span style={{ display: "block", fontSize: "22px", fontWeight: "bold", }}>
                                {greeting} <span>{toTitleCase(userdetails?.firstname)} <span>!</span> </span>
                            </span>
                            <span>
                                Welcome back ! Hope you have a great day ahead !
                            </span>
                        </div>


                    </div>



                    <div className='dash-cards tw-mb-3' style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <div className='inner-div' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                            <Row style={{ width: "100%" }}>
                                <Col lg={3} md={6} sm={12} className="m-0 p-0">
                                    <div className='response'>
                                        <TopCards
                                            width={'96%'}
                                            cursor={"normal"}
                                            navigateTo={'/ems/users'}
                                            header={"Total Employee"}
                                            backgroundcolor={'#ADD8E6'}
                                            total_user={total_user}
                                        />
                                    </div>
                                </Col>

                                <Col lg={3} md={6} sm={12} className="m-0 p-0">
                                    <div className='response' onClick={() => {
                                        handleOpenM()
                                        settoday_data("Present")
                                    }}>
                                        <TopCards
                                            width={'96%'}
                                            cursor={"pointer"}
                                            navigateTo={'/hr/EmpAttendance'}
                                            header={"Total Attendance"}
                                            backgroundcolor={'#90EE90'}
                                            total_user={total_attendance}
                                        />
                                    </div>
                                </Col>

                                <Col lg={3} md={6} sm={12} className="m-0 p-0">
                                    <div className='response' onClick={() => {
                                        handleOpenM2()
                                        settoday_data("Absence")
                                    }}>
                                        <TopCards
                                            width={'96%'}
                                            cursor={"pointer"}
                                            navigateTo={'/ems/attendance/myAttendance/MyAttendanceCalenderView'}
                                            header={"Total Absence"}
                                            backgroundcolor={"#FFA07A"}
                                            total_user={total_absence}

                                        />

                                    </div>
                                </Col>

                                <Col lg={3} md={6} sm={12} className="m-0 p-0">
                                    <div className='response' onClick={() => {
                                        handleOpenM2()
                                        settoday_data("Leave")
                                    }}>
                                        <TopCards
                                            width={'96%'}
                                            cursor={"pointer"}
                                            navigateTo={'/ems/leave'}
                                            header={"Total Leave"}
                                            backgroundcolor={'#D8BFD8'}
                                            total_user={leave_data}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>

                    <div className='issue-cards tw-mb-3'>

                        <div className='issue-first'>
                            <div>
                                <span style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>Issue Tracker Overview .</span>
                            </div>
                            <div>
                                <span>Snapshot of the current state of issues in our system. Keep track of your progress and stay on top of what needs attention!</span>
                            </div>
                        </div>


                        <div className='issue-second'>
                            <div className='container-between'>
                                <Col lg={3} md={3} sm={12} className="m-0 p-0 issue-card">
                                    <IssueCards
                                        navigateTo={'/support/RaiseIssue'}
                                        background={' linear-gradient(356deg, rgba(77,191,231,1) 0%, rgba(67,165,223,1) 0%, rgba(51,158,207,1) 0%, rgba(154,194,231,1) 0%, rgba(86,162,244,1) 0%, rgba(129,158,240,1) 0%, rgba(80,184,238,1) 0%, rgba(77,165,234,1) 0%, rgba(185,185,245,1) 1%, rgba(172,183,244,1) 3%, rgba(99,153,249,1) 6%, rgba(78,167,234,1) 7%, rgba(82,164,237,1) 9%, rgba(79,170,235,1) 10%, rgba(25,147,180,1) 52%, rgba(3,119,143,1) 93%)'}

                                        title={'Total Issue'}
                                        value={total_issue}


                                    />
                                </Col>


                                <Col lg={3} md={3} sm={12} className="m-0 p-0 issue-card">
                                    <IssueCards
                                        background={'linear-gradient(0deg, rgba(91,194,174,1) 0%, rgba(77,252,70,1) 100%)'}
                                        title={'Total Resolved'}
                                        value={totalsolved_issue}
                                        navigateTo={'/support/RaiseIssue'}
                                    />
                                </Col>

                                <Col lg={3} md={3} sm={12} className="m-0 p-0 issue-card">
                                    <IssueCards
                                        background={'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(151,192,113,1) 0%, rgba(190,192,85,1) 4%, rgba(186,190,99,1) 12%, rgba(181,186,90,1) 27%, rgba(211,177,52,1) 64%, rgba(240,168,15,1) 100%)'}
                                        title={'Total Pending'}
                                        value={total_pending_issue}
                                        navigateTo={'/support/RaiseIssue'}
                                    />
                                </Col>

                            </div>
                        </div>
                    </div>


                    <div className='tw-mb-3' style={{
                        display: "grid",
                        height: "300px",
                        gridTemplateColumns: "1fr",
                        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                    }}>
                        <StackedBar data={stackedbar} />
                    </div>

                </div>
                {/* ----------side bar------- */}
            </div>
        </div>
    )
}

export default NewDasboard