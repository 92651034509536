import React from 'react'
import './NewDasboard.css'
import { useNavigate } from 'react-router-dom';

const IssueCards = ({ navigateTo, background, value, title }) => {

    const navigate = useNavigate();

    // Navigate to the route passed via props
    const handleNavigation = () => {
        navigate(navigateTo);
    };



    return (
        <div className='IssueCards' style={{
            background: background,
        }}>

            <div className='total-num'>
                {value}
            </div>

            <div className='lable-conter'>
                {title}
            </div>

            <div className='view-more'>
                <div className='view-btn' onClick={handleNavigation}>View More</div>
            </div>

        </div>
    )
}

export default IssueCards