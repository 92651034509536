import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsGear, BsBell } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { FaAngleDown } from "react-icons/fa";
// import localforage from "localforage";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { ServerAddress } from "../../constants/ServerAddress";
import axios from "axios";
import {
  setAlertType,
  setDataExist,
  setShowAlert,
} from "../../store/alert/Alert";
import {
  setAccessToken,
  setRefreshToken,
  setUserDetails,
} from "../../store/authentication/Authentication";
import { setNavToggle } from "../../store/dataList/DataList";
import toTitleCase from "../../lib/titleCase/TitleCase";
import NotificationDropdown from "../topbarDropdown/NotificationDropdown";
import ProfileMenu from "../topbarDropdown/ProfileMenu";
import SwitchAccount from "../topbarDropdown/switchaccount";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userdetails = useSelector((state) => state.authentication.userdetails);

  const login_id = useSelector((state) => state.authentication.login_id);
  const accessTK = useSelector((state) => state.authentication.access_token);
  const show_alert = useSelector((state) => state.alert.show_alert);
  const data_exist = useSelector((state) => state.alert.data_exist);
  const alert_type = useSelector((state) => state.alert.alert_type);

  const send_logout_time = () => {
    if (login_id) {
      axios
        .post(
          ServerAddress + "ems/set_logout_time/",
          {
            login_id: login_id,
          },
          {
            headers: { Authorization: `Bearer ${accessTK}` },
          },
        )
        .then(function (response) {})
        .catch(function () {
          alert("Error Occur While Sending Logout Data");
        });
    }
  };

  useEffect(() => {
    if (show_alert) {
      setTimeout(() => {
        dispatch(setShowAlert(false));
      }, 4000);  // 4 sec
    }
  }, [show_alert]);


  const [sidebarActive, setSidebarActive] = useState(true);
  useEffect(() => {
    dispatch(setNavToggle(false));
  }, []);

  const handleClk = () => {
    setSidebarActive(!sidebarActive);
    dispatch(setNavToggle(sidebarActive));
  };
  const nav_toggle = useSelector((state) => state.datalist.nav_toggle);

  // for full screen
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <header>
      {show_alert ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert
            style={{
              position: "absolute",
              right: nav_toggle ? "200px" : "65px",
              top: "2vh",
              width: "75vw",
              zIndex: 200,
            }}
            color={alert_type}
            toggle={() => {
              dispatch(setDataExist(""));
              dispatch(setAlertType(""));
              dispatch(setShowAlert(""));
            }}
          >
            {data_exist}
          </Alert>
        </div>
      ) : null}

      <div
        style={{ width: "100%"}}
        className={
          sidebarActive ? "margin-left-20rem navbar-header" : "navbar-header"
        }
      >
        <div
          className="header-container align-items-center d-flex"
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "space-between",
            flexDirection: userdetails.is_onboarded ? 'row':'row-reverse',

          }}
        >
          {((!userdetails.is_superuser && userdetails.is_onboarded) ||
            userdetails.is_superuser) && (
            <button
              className={"hamburgerButton"}
              type="button"
              onClick={() => handleClk()}
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          )}
          

          <div>
            <nav style={{ cursor: "pointer" }}>
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon "
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button>
              </div>

              {userdetails.is_onboarded && <NotificationDropdown />}
              <ProfileMenu />
              {userdetails.is_onboarded && (
                <BsGear
                  className="rotate"
                  style={{ fontSize: "1.25rem", margin: "0 1rem" }}
                  onClick={() => {
                    navigate("/screens/setting/Setting");
                  }}
                />
              )}
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
