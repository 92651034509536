const DashboardTitle = [
  "Employee Id",
  "Name",
  "Clock In ",
  "Email",
  "Reporting manager",
  "Branch",
];

export default DashboardTitle;
