import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { useSelector } from 'react-redux';
import { ServerAddress } from '../../../constants/ServerAddress';
import './Test.css';

const Hierarchy = () => {
  const accessToken = useSelector((state) => state.authentication.access_token);
  const user = useSelector((state) => state.authentication.userdetails);
  const [rootData, setRootData] = useState(null);

  useEffect(() => {
    const getTeam = async () => {
      try {
        const response = await axios.get(
          ServerAddress +
          `ems/group_all/?search=${''}&p=${''}&records=${10}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          },
        );
        if (response.data.results !== "") {
          const rootData = {
            name: user.organization_name,
            attributes: {
              Title: "Organization Name"
            },
            children: response.data
          };
          // Convert my_data to rootData format
          setRootData(rootData);
        }
      } catch (err) {
        alert(`Error Occurred in Get team: ${err}`);
      }
    };

    getTeam();
  }, [accessToken]);


  return (
    <>
      <div className='header_Test'>
        <span>Click a node to explore the team structure !</span>
      </div>
      <div style={{ width: '100%', height: '700px', display: "flex", justifyContent: "center", alignItems: "center" }}>
        {rootData &&
          <Tree
            data={rootData}
            orientation="vertical"
            translate={{ x: 100, y: 100 }}
            separation={{ siblings: 2, nonSiblings: 2 }}
            transitionDuration={500}
          />
        }
      </div>
    </>
  );
};

export default Hierarchy;
