import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { ServerAddress } from '../../../constants/ServerAddress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchInput from '../../../components/formComponent/searchInput/SearchInput';
import toTitleCase from '../../../lib/titleCase/TitleCase';
import { IconContext } from "react-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlertType, setDataExist, setShowAlert } from '../../../store/alert/Alert';

import {
	Form,
	Input,
	Col,
	Card,
	CardBody,
	CardTitle,
	Label,
	Row,
	FormFeedback,
} from "reactstrap";
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/pageTitle/PageTitle';
import Title from '../../../components/title/Title';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import Loader from '../../../data/loader/Loader';



const OfferLetterTemplates = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const accessToken = useSelector((state) => state.authentication.access_token);
	const page_num = useSelector((state) => state.pagination.page_number);
	const data_len = useSelector((state) => state.pagination.data_len);


	// toggle
	const [circle_btn, setcircle_btn] = useState(true);
	const toggle_circle = () => {
		setcircle_btn(!circle_btn);
	};

	const [circle_btn1, setcircle_btn1] = useState(true);
	const toggle_circle1 = () => {
		setcircle_btn1(!circle_btn1);
	};
	const [point1, setpoint1] = useState('')
	const [point2, setpoint2] = useState('')
	const [point3, setpoint3] = useState('')
	const [point4, setpoint4] = useState('')
	const [point5, setpoint5] = useState('')
	const [point6, setpoint6] = useState('')
	const [point7, setpoint7] = useState('')
	const [point8, setpoint8] = useState('')
	const [point9, setpoint9] = useState('')
	const [point10, setpoint10] = useState('')

	const [offerletter_id, setofferletter_id] = useState('')

	// department state=========

	const [loader, setloader] = useState(false);

	// designation state ====
	const [designationNameList, setDesignationNameList] = useState("");
	const [search_designation, setsearch_designation] = useState("");
	const [designation, setdesignation] = useState("")
	const [page_designation, setpage_designation] = useState(1);
	const [designation_id, set_designation_id] = useState("");
	const [prev_designation, setprev_designation] = useState("");
	console.log('designation_id====', designation_id)
	const [desigation_err, set_desigation_err] = useState("");
	const [designation_count, set_designation_count] = useState(1);
	const [designation_loaded, set_desigation_loaded] = useState(false);
	const [desigation_bottom, set_designation_bottom] = useState(103);

	// department list 
	const [user_department_list, setuser_department_list] = useState([]);
	const [user_department, setuser_department] = useState("");
	const [user_department_id, setuser_department_id] = useState("");
	const [prev_department_id, setprev_department_id] = useState('')

	console.log('user_department_id----', user_department_id)
	const [user_department_page, setuser_department_page] = useState(1);
	const [user_department_search, setuser_department_search] = useState("");
	const [user_department_loaded, setuser_department_loaded] = useState(false);
	const [user_department_count, setuser_department_count] = useState(1);
	const [user_department_bottom, setuser_department_bottom] = useState(103);
	const [user_department_err, setuser_department_err] = useState(false);

	// is updating --------- 
	const [is_updating, setis_updating] = useState(false)


	const showToastMessage = () => {
		toast.success('Template Updated!', {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 1000,
			hideProgressBar: true,
		});
	};


	const get_department = async () => {
		try {
			let depart = [];
			const response = await axios.get(
				ServerAddress +
				`organization/get_department/?search=${user_department_search}&p=${user_department_page}&records=${10}`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				},
			);
			console.log("get_departmentresponse: ", response);
			if (response.data.next === null) {
				setuser_department_loaded(false);
			} else {
				setuser_department_loaded(true);
			}
			if (response.data.results.length > 0) {
				if (user_department_page === 1) {
					depart = response.data.results.map((v) => [
						v.id,
						toTitleCase(v.name),
					]);
				} else {
					depart = [
						...user_department_list,
						...response.data.results.map((v) => [v.id, toTitleCase(v.name)]),
					];
				}
				setuser_department_count(user_department_count + 2);
				setuser_department_list(depart);
			} else {
				setuser_department_list([]);
			}
		} catch (err) {
			alert(`Error Occur in Get Departments, ${err}`);
		}
	};

	useEffect(() => {
		get_department();
	}, [user_department_page, user_department_search]);



	const getDesignation = () => {
		let temp3 = [];
		axios
			.get(
				ServerAddress +
				`ems/Get-Designation/?search=${search_designation}&p=${page_designation}&records=${10}&filter_designation=${user_department_id}`,
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				},
			)
			.then((response) => {
				console.log("kkk", response)
				if (response.data.next === null) {
					set_desigation_loaded(false);
				} else {
					set_desigation_loaded(true);
				}

				if (response.data.results.length > 0) {
					console.log(response);
					if (page_designation === 1) {
						temp3 = response.data.results.map((v) => [
							v.id,
							toTitleCase(v.designation),
						]);
					} else {
						temp3 = [
							...designationNameList,
							...response.data.results.map((v) => [
								v.id,
								toTitleCase(v.designation),
							]),
						];
					}
					set_designation_count(designation_count + 2);
					setDesignationNameList(temp3);
				} else {
					setDesignationNameList([]);
				}
			})
			.catch((err) => {
				alert(`Error Occur in Get`, err);
			});
	};
	useEffect(() => {
		if (user_department_id !== prev_department_id) {
			setDesignationNameList("")
			setdesignation("")
			set_designation_id("")
			setpoint1("")
			setpoint2("")
			setpoint3("")
			setpoint4("")
			setpoint5("")
			setpoint6("")
			setpoint7("")
			setpoint8("")
			setpoint9("")
			setpoint10("")
		}
		getDesignation()
	}, [search_designation, page_designation, user_department_id])

	useEffect(() => {
		if (designation_id !== prev_designation) {
			setpoint1("")
			setpoint2("")
			setpoint3("")
			setpoint4("")
			setpoint5("")
			setpoint6("")
			setpoint7("")
			setpoint8("")
			setpoint9("")
			setpoint10("")
		} else if (user_department_id === prev_department_id && designation_id === prev_designation) {
			let data = location?.state?.user;
			setpoint1(data?.point1)
			setpoint2(data?.point2)
			setpoint3(data?.point3)
			setpoint4(data?.point4)
			setpoint5(data?.point5)
			setpoint6(data?.point6)
			setpoint7(data?.point7)
			setpoint8(data?.point8)
			setpoint9(data?.point9)
			setpoint10(data?.point10)
		}
	}, [prev_designation, designation_id])



	const AddOffer = (values) => {
		axios
			.post(
				ServerAddress + "HR/addoffer_letter/",
				{
					designation: designation_id,
					department: user_department_id,
					point1: point1,
					point2: point2,
					point3: point3,
					point4: point4,
					point5: point5,
					point6: point6,
					point7: point7,
					point8: point8,
					point9: point9,
					point10: point10,
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				},
			)
			.then(function (resp) {
				console.log("response ===>", resp.data);
				if (resp.status === 201) {
					dispatch(setShowAlert(true));
					dispatch(setDataExist(`OfferLetter Created Sucessfully`));
					dispatch(setAlertType("success"));
					navigate('/OfferLetterTemplates/OfferLetter');
				}if (resp.data.message === "Template Exists") {
					dispatch(setShowAlert(true));
					dispatch(setDataExist(`Template Already Exists`));
					dispatch(setAlertType("warning"));
				}
			})
			.catch((err) => {
				alert(`error occur while adding`);
			});
	};


	const Updateoffer_letter = (Approved) => {
		axios
			.put(
				ServerAddress + "HR/putoffer_letter/" + offerletter_id,
				{
					designation: designation_id,
					department: user_department_id,
					point1: point1,
					point2: point2,
					point3: point3,
					point4: point4,
					point5: point5,
					point6: point6,
					point7: point7,
					point8: point8,
					point9: point9,
					point10: point10,
				},
				{
					headers: { Authorization: `Bearer ${accessToken}` },
				},
			)
			.then((response) => {
				console.log('response==>>>>', response)
				if (response.data.status === "success") {
					dispatch(setShowAlert(true));
					dispatch(setAlertType("success"));
					dispatch(
						setDataExist(`Offer letter Updated Successfully`),
					);
					navigate('/OfferLetterTemplates/OfferLetter');
				}if (response.data.message === "Template Exists") {
					dispatch(setShowAlert(true));
					dispatch(setDataExist(`Template Already Exists`));
					dispatch(setAlertType("warning"));
				}
			})
			.catch((error) => {
				alert(`Error While Updating ${error}`);
			});
	};

	useLayoutEffect(() => {
		try {
			let data = location.state.user;
			setis_updating(true);
			setofferletter_id(data.id)
			console.log("data--", data)
			set_designation_id(data?.designation)
			setprev_designation(data?.designation)
			setdesignation(data?.designation_name)

			setuser_department_id(data?.department)
			setprev_department_id(data?.department)

			setuser_department(data?.department_name)
			setpoint1(data?.point1)
			setpoint2(data?.point2)
			setpoint3(data?.point3)
			setpoint4(data?.point4)
			setpoint5(data?.point5)
			setpoint6(data?.point6)
			setpoint7(data?.point7)
			setpoint8(data?.point8)
			setpoint9(data?.point9)
			setpoint10(data?.point10)
		} catch (error) {
		}
	}, [is_updating]);




	return (
		<div className='offer'>
			<ToastContainer />
			<div className="m-4">
				<PageTitle
					page={is_updating ? "Update Generated Offer Letter" : "Add Generate Offer Letter"}
				/>
				<Title
					title={is_updating ? "Update Generated Offer Letter" : "Add Generate Offer Letter"}
					parent_title="HR"
				/>
			</div>
			<div className="m-3">
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						if (designation_id === "") {
							set_desigation_err(true);
							document.getElementById("offer").scrollIntoView();
						} else if (user_department_id === "") {
							setuser_department_err(true);
							document.getElementById("offer").scrollIntoView();
						}
						else {
							is_updating ? Updateoffer_letter() : AddOffer();
						}
						return false;
					}}>
					<div className="m-4" id="user_info">

						<Col lg={12}>
							<Card className="shadow bg-white rounded">
								<CardTitle className="mb-1 header">
									<div className="header-text-icon header-text">
										<div>Project Details</div>
										<IconContext.Provider
											value={{
												className: "header-add-icon",
											}}
										>
											<div onClick={toggle_circle}>
												{circle_btn ? (
													<MdRemoveCircleOutline />
												) : (
													<MdAddCircleOutline />
												)}
											</div>
										</IconContext.Provider>
									</div>
								</CardTitle>
								{circle_btn ? (
									<CardBody>
										<Row>
											<Col lg={4} md={6} sm={6}>
												<div className="mb-2">
													<Label className="header-child">Department </Label>
													<SearchInput
														data_list={user_department_list}
														set_data_list={setuser_department_list}
														data_item_s={user_department}
														set_data_item_s={setuser_department}
														set_id={setuser_department_id}
														page={user_department_page}
														setpage={setuser_department_page}
														error_message={"Please Select Any Department"}
														error_s={user_department_err}
														search_item={user_department_search}
														setsearch_item={setuser_department_search}
														loaded={user_department_loaded}
														count={user_department_count}
														bottom={user_department_bottom}
														setbottom={setuser_department_bottom}
													/>
												</div>
											</Col>

											<Col lg={4} md={6} sm={6}>
												<div className="mb-2">
													<Label className="header-child">Designation </Label>
													<SearchInput
														data_list={designationNameList}
														set_data_list={setDesignationNameList}
														data_item_s={designation}
														set_data_item_s={setdesignation}
														set_id={set_designation_id}
														page={page_designation}
														setpage={setpage_designation}
														error_message={"Please Select Any Designation"}
														error_s={desigation_err}
														search_item={search_designation}
														setsearch_item={setsearch_designation}
														loaded={designation_loaded}
														count={designation_count}
														bottom={desigation_bottom}
														setbottom={set_designation_bottom}
													/>
												</div>
											</Col>

										</Row>
									</CardBody>
								) : null}
							</Card>
						</Col>
					</div>

					<div className="m-4" id='offer'>
						<Card className="shadow bg-white rounded">
							<CardTitle className="mb-1 header">
								<div className="header-text-icon header-text">
									<div>Set Bullet Points</div>
									<IconContext.Provider
										value={{
											className: "header-add-icon",
										}}
									>
										<div onClick={toggle_circle1}>
											{circle_btn1 ? (
												<MdRemoveCircleOutline />
											) : (
												<MdAddCircleOutline />
											)}
										</div>
									</IconContext.Provider>
								</div>
							</CardTitle>
							{circle_btn1 ? (
								<CardBody>
									<Row>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 1</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point1}
													onChange={(e) => {
														setpoint1(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 2</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point2}
													onChange={(e) => {
														setpoint2(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 3</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point3}
													onChange={(e) => {
														setpoint3(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 4</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point4}
													onChange={(e) => {
														setpoint4(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 5</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point5}
													onChange={(e) => {
														setpoint5(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 6</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point6}
													onChange={(e) => {
														setpoint6(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 7</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point7}
													onChange={(e) => {
														setpoint7(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 8</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point8}
													onChange={(e) => {
														setpoint8(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 9</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point9}
													onChange={(e) => {
														setpoint9(e.target.value);
													}}
												/>
											</div>
										</Col>
										<Col lg={4} md={6} sm={6}>
											<div className="mb-2">
												<Label className="tw-mt-2" for="exampleText">Point 10</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													style={{ height: "90px" }}
													value={point10}
													onChange={(e) => {
														setpoint10(e.target.value);
													}}
												/>
											</div>
										</Col>
									</Row>
								</CardBody>
							) : null}
						</Card>
					</div>
					<div className=" m-4">
						<Col lg={12}>
							<div className="mb-1 footer_btn">
								{!loader ? (
									<button className="btn btn-success m-1">
										{is_updating ? "Update" : "Save"}
									</button>
								) : (
									<button
										type="button"
										className="btn btn-success m-1"
									>
										<Loader />
									</button>
								)}
								<button
									type="button"
									className="btn btn-danger"
									onClick={() => {
										navigate("/OfferLetterTemplates/OfferLetter");
									}}
								>
									Cancel
								</button>
							</div>
						</Col>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default OfferLetterTemplates;
