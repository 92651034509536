import React from "react";
import TimeLine from "./timeLineSection/addTimeline/TimeLine";
import useWindowDimensions from "./ScreenSize";
import "./Dashboard.css";
import { MdOutlineAddBox } from "react-icons/md";
import { IoBookmarksSharp } from "react-icons/io5";
import Birtday from "./dashboardIcons/Birtday.png";
import Gmail from "./dashboardIcons/Gmail.png";
import BookMarks from "./dashboardIcons/BookMarks.png";
import Calendar from "./dashboardIcons/Calendar.png";
import Whatsapp from "./dashboardIcons/Whatsapp.png";
import Attendance from "./dashboardIcons/Attendance.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Carousel.css";
import BirthdayModal from "./timeLineSection/birthDay/BirthdayModal";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import { useState, useEffect, useLayoutEffect } from "react";
import { ServerAddress } from "../../constants/ServerAddress";
import axios from "axios";
import "../../screens/dashboard/timeLineSection/birthDay/BirthdayModal.css";
import { SlCalender } from "react-icons/sl";
import { FaChevronCircleRight, FaGift } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { IoMdMail } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Include carousel styles
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaCircleChevronLeft } from "react-icons/fa6";



const DashboardTimelineSection = ({ holiday_data }) => {

  const [currentIndex, setCurrentIndex] = useState(0);


  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === holiday_data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? holiday_data.length - 1 : prevIndex - 1
    );
  };

  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.authentication.access_token);
  const success = useSelector((state) => state.alert.show_alert);
  const [blogData, setBlogData] = useState([]);

  const handleHolidayclick = () => {
    navigate('/organisation/holidays/Holiday');
  };


  const getdepartment = () => {
    axios
      .get(ServerAddress + `notice_board/get-Notice/?&p=${1}&records=${10}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        console.log("response me kya aa rha ha", response)
        setBlogData(response.data.results);
      })
      .catch((err) => {
        console.log(`Error Occur in Get , ${err}`);
      });
  };
  useEffect(() => {
    if (accessToken) {
      getdepartment()
    }
  }, [])

  const [file_is, setfile_is] = useState("");

  // To Get Profile Image

  const fileInputRef = React.useRef(null);

  function handleClick() {
    alert("Select");
    fileInputRef.current.click();
  }

  function handleFileSelected(event) {
    const file = event?.target?.files[0];
    setfile_is(URL.createObjectURL(file));
  }

  const [show, setShow] = useState(false);
  const [select_img, setselect_img] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const Image_Is = (file) => {
    handleShow();
    setselect_img(file);
  };

  const [birthday_List, setbirthday_List] = useState([]);
  console.log("birthday_List----------", birthday_List);

  //----------test data------------//

  let birthday = [
    {
      Date: "2000/10/18",
      profile:
        "https://yt3.ggpht.com/ytc/AAUvwnh7CHDDIuu6a87VwcIoDe-UeDb-Cfu8Ig8utAcGBDw=s900-c-k-c0x00ffffff-no-rj",
    },
    {
      Date: "2000/10/13",
      profile:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP3lsMLSbq5aYnxUvJzPj3fjtu2Xnz511zDr_z1f8&usqp=CAE&s",
    }, //sunny
    {
      Date: "2000/10/13",
      profile:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAgiSk1b80SlUpBYGzak39z3voqLShJ9ef8jmGZaY&usqp=CAE&s",
    }, //rahul
    {
      Date: "2000/01/18",
      profile:
        "https://yt3.ggpht.com/ytc/AAUvwnh7CHDDIuu6a87VwcIoDe-UeDb-Cfu8Ig8utAcGBDw=s900-c-k-c0x00ffffff-no-rj",
    }, //modi
  ];
  let profile_photo =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP3lsMLSbq5aYnxUvJzPj3fjtu2Xnz511zDr_z1f8&usqp=CAE&s";



  //-------------------------------------//

  const today = new Date();
  const todayMonthDay =
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    today.getDate().toString().padStart(2, "0");

  let today_birthday = birthday_List.filter((item) => {
    if (item.Date !== null) {
      const itemMonthDay =
        item.Date.split("-")[1] + "-" + item.Date.split("-")[2];
      // const itemMonthDay = item.Date;
      return itemMonthDay === todayMonthDay;
    }
    return false;
  });


  //---------For getting birthday-------------//

  const get_birthday = () => {
    let final = [];

    axios
      .get(ServerAddress + `ems/get_user_birthday/?p=1`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        let pair = response.data.results;
        pair.forEach((item) => {
          final.push({
            Date: item.date_of_birth,
            profile: item.profile_pic,
          });
        });
        setbirthday_List(final);
      })
      .catch((error) => {
        console.log(`Error while in get data,${error}`);
      });
  };

  useEffect(() => {
    if (accessToken) {
      get_birthday();
    }
  }, []);

  // To get Screen Size
  const { height } = useWindowDimensions();
  return (
    <>


      <Modal show={show} onHide={handleClose} className="custom_modal">
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontFamily: "'Poppins', sans-serif", fontSize: "400" }}
          >
            {" "}
            Today's Birthdays
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#454545" }}>
          {today_birthday.length === 0 ? (
            <div
              className="birt_day"
              style={{
                fontFamily: "Poppins sans-serif",
                fontSize: "400",
                color: "white",
              }}
            >
              NO Birthdays Today
            </div>
          ) : (
            <>
              <BirthdayModal />;
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ fontFamily: "'Poppins', sans-serif", fontSize: "400" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div style={{ boxShadow: "-2px 1px 5px 0px rgba(235,235,235,1)", }}>

        <div className="tw-mb-2" >

          <div className="tw-mb-2 tw-mt-1" style={{
            height: height / 4,
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            gap: "6px",
            backgroundColor: 'rgba(217, 217, 217, 0.2)',
            borderRadius: "10px",
            boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
          }}>

            <div className="holiday-title">


              <div style={{ width: "90%", height: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start", textAlign: "center", gap: "6px", boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px' }}>
                <span style={{ textDecoration: "underline", fontSize: "bold" }}>
                  <SlCalender />
                </span>
                <span>
                  Upcomming Holiday
                </span>
              </div>
              <div style={{ width: "auto", }}>
                <FaChevronCircleRight />
              </div>

            </div>

            {/* <div className="holiday-main">

              <div className="holiday-main-inc">
                <div className="img-cont" style={{ boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset' }}>img</div>
                <div style={{ display: "flex", textAlign: "center", alignItems: "center", justifyContent: "flex-start", height: "100%", width: "100%", }}>
                  Etechcube wishes everyone a happy Durga Puja!</div>
              </div>


            </div> */}
            <div className="holiday-main">
              <div onClick={prevSlide}>
                <FaCircleChevronLeft className="before" size={16} />
              </div>

              <div
                className="carousel-wrapper"
                style={{
                  display: "flex",
                  overflow: "hidden",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div
                  className="carousel-content"
                  style={{
                    display: "flex",
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: "transform 0.5s ease-in-out",
                    width: "100%",
                  }}
                >
                  {holiday_data?.map((item, index) => (
                    <div
                      className="holiday-main-inc"
                      key={index}
                      style={{
                        flex: "0 0 100%"
                      }}
                    >
                      <div
                        className="img-cont"
                        style={{
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                        }}
                      >
                        <img
                          src={item?.holiday_img}
                          alt="holiday_img"
                          style={{ width: "100%", height: "100%", overflow: "hidden" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        Etechcube wishes everyone a {item?.name} !
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Carousel Controls */}

              <div onClick={nextSlide}>
                <FaCircleChevronRight className="before" size={16} />
              </div>
            </div>



            <div className="view-button">
              <div style={{ width: "auto", padding: '0px 6px 0px 6px', color: "white", background: "black", borderRadius: "20px" }}
                onClick={handleHolidayclick}
              >View More</div>
            </div>


          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#F1EEEE",
            }}
          >
            <div className="timline_heading">
              <span>Timeline</span>
            </div>
            <div
              style={{ cursor: "pointer", margin: "10px 10px 10px 15px" }}
              onClick={() => {
                navigate("/dashboard/timeLineSection/addTimeline/AddTimeline");
              }}
            >
              <MdOutlineAddBox size={22} />
            </div>
          </div>

          <div
            className="custom-scrollbars__content"
            style={{
              height: height / 4,
              overflowY: "scroll",
              background: "white",
              padding: "2px",
              marginTop: "4px",
            }}
          >
            {blogData.map((val, index) => {
              // <li key={a.id}>{a.text}</li>
              let f_date_f = val.created_at.split("T");
              let f_date = f_date_f[0];
              return (
                <TimeLine
                  title={val.title}
                  text={val.description}
                  author={val.user_name}
                  date={f_date}
                  comment={val.comment}
                  avatarSrc={val.employee_image}
                />
              );
            })}
          </div>

        </div>




        <div className="notification_Card_container"
          style={{ boxShadow: "0px -5px 5px -4px rgba(242,242,242,0.93)", height: height / 4.5,}}>

          <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",}}>

            <div className="notification_row">
              {today_birthday.length !== 0 ? (
                <span style={{width:"50px",display:"flex",justifyContent:"center"}}>
                  <AliceCarousel infinite autoPlay autoPlayInterval={2000} controlsStrategy="responsive" items={1}  >
                    {today_birthday.map((bday, index) => {
                      // if (bday.Date === today) {
                      return (
                        <div className="notity_card"
                          key={index}
                          style={{
                            display: "flex",
                            position: "relative",
                            borderRadius: "50%",
                            // background: "red",
                            minHeight: "100%",
                            maxHeight: "100%",
                            minWidth: "100%",
                            maxWidth: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={handleShow}
                        >
                          <img
                            src={bday.profile}
                            alt="No Profile Picture"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                              width: "100%",
                            }}
                          />
                          <FaGift size={50} color="yellow" />
                        </div>
                      );
                      // }
                      // return null;
                    })}
                  </AliceCarousel>
                </span>
              ) : (
                <span className="notity_card">
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleShow}
                  >
                    {/* <img
                      src={Birtday}
                      alt="No Profile Picture"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    /> */}
                    <FaGift size={50} color="yellow" />


                  </div>
                </span>
              )}

              <span className="notity_card" >
                {/* <img
                  onClick={() => {
                    navigate("/organisation/holidays/Holiday");
                  }}
                  src={Calendar}
                  alt="Calendar"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    // borderRadius: "50%",
                    width: "100%",
                  }}
                /> */}
                <SlCalender
                  size={50}
                  color="blue"
                  onClick={() => {
                    navigate("/organisation/holidays/Holiday");
                  }} />

              </span>

              <span className="notity_card" >
                {/* <img
                  onClick={() => {
                    navigate(
                      "/ems/attendance/myAttendance/MyAttendanceCalenderView",
                    );
                  }}
                  src={Attendance}
                  alt="Attendance"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    // borderRadius: "50%",
                    width: "100%",
                  }}
                /> */}
                <ImProfile
                  color="blue"
                  size={50}
                  onClick={() => {
                    navigate(
                      "/ems/attendance/myAttendance/MyAttendanceCalenderView",
                    );
                  }}
                />

              </span>
            </div>

            <div className="notification_row1">
              <span className="notity_card">
                <a
                  href="https://accounts.google.com/v3/signin/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&ifkv=AYZoVhcSpnx_YjPFoLvSJdolmSi9y4EIAAYeRM6wmGzFtZgpALWn1huHkj9JdxmmaVX1Fv9yUUPi5g&rip=1&sacu=1&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&dsh=S134951283%3A1695446818780277&theme=glif"
                  target="_blank"
                >
                  {/* <img src={Gmail} alt="Gmail"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      // borderRadius: "50%",
                      width: "100%",
                    }}
                  /> */}
                  <IoMdMail
                    color="blue"
                    size={50}
                  />

                </a>
              </span>

              <span className="notity_card" >
                <a href="https://web.whatsapp.com/" target="_blank">
                  {/* <img src={Whatsapp} alt="Whatsapp"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      // borderRadius: "50%",
                      width: "100%",
                    }}
                  /> */}
                  <RiWhatsappFill color="green" size={50} />


                </a>
              </span>

              <span className="notity_card">
                {/* <img
                  onClick={() => {
                    alert("Work is going On");
                  }}
                  src={BookMarks}
                  alt="BookMark"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    // borderRadius: "50%",
                    width: "100%",
                  }}
                /> */}
                <IoBookmarksSharp size={50} color="#E4B1F0" />


              </span>
            </div>
          </div>

        </div>


      </div>
    </>
  );
};

export default DashboardTimelineSection;
