import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ServerAddress } from "../../constants/ServerAddress";
import {
  setDeleteId,
  setIndexValue,
  setSelect,
  setIds,
  setClose,
} from "../../store/dataList/DataList";
import { FiCheckSquare, FiSquare } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  setAlertType,
  setShowAlert,
  setDataExist,
} from "../../store/alert/Alert";
import { setIsDeleted, setToggle } from "../../store/pagination/Pagination";
import toTitleCase from "../../lib/titleCase/TitleCase";
import DateConvertor from "../../components/dateConvertor/DateConvertor";
import correct from "../../assets/images/bookings/check-mark.png";
import cross from "../../assets/images/bookings/remove.png";



const DashboardUserData = ({ data, data1, can_delete }) => {

  const dispatch = useDispatch();

  const total_data = useSelector((state) => state.pagination.total_data);
  const AccessToken = useSelector((state) => state.authentication.access_token);
  const data_len = useSelector((state) => state.pagination.data_length);
  const page_num = useSelector((state) => state.pagination.page_number);

  const [refresh, setrefresh] = useState(false);


  useEffect(() => {
    dispatch(setToggle(false));
  });

  useEffect(() => {
    dispatch(setIsDeleted("No"));
  }, [total_data]);

  //For Sorting
  const list_toggle = useSelector((state) => state.datalist.list_toggle);
  const index = useSelector((state) => state.datalist.index);

  useEffect(() => {
    if (index === 0) {
      dispatch(setIndexValue("employee_id"));
    } else if (index === 1) {
      dispatch(setIndexValue("firstname"));
    } else if (index === 2) {
      dispatch(setIndexValue("email"));
    } else if (index === 3) {
      dispatch(setIndexValue("reporting_manager_firstname"));
    } else if (index === 4) {
      dispatch(setIndexValue("home_branch_name"));
    } 
  }, [index]);

  //Multi Delete function
  const ids = useSelector((state) => state.datalist.ids);
  const select_all = useSelector((state) => state.datalist.select_all);
  const delete_id = useSelector((state) => state.datalist.delete_id);

  const [selected, setselected] = useState([]);

  const handlefunn = (id) => {
    if (selected.includes(id)) {
      let lis = [...selected];
      setselected(lis.filter((e) => e !== id));
    } else {
      setselected([...selected, id]);
    }
  };

  useEffect(() => {
    dispatch(setIds(selected));
  }, [selected]);

  useEffect(() => {
    if (ids !== "" && select_all === true) {
      setselected(ids);
    }
  }, [ids, select_all]);

  useEffect(() => {
    if (select_all === false) {
      setselected([]);
    }
  }, [select_all]);

//   useEffect(() => {
//     if (delete_id === true) {
//       delete_item_row(ids);
//     }
//   }, [delete_id, ids]);

  // Permission
  const userpermission = useSelector(
    (state) => state.authentication.userpermission,
  );
  const user = useSelector((state) => state.authentication.userdetails);
  const [can_update, setcan_update] = useState(false);

  useEffect(() => {
    if (
      userpermission.some((e) => e.sub_model === "Blog" && e.update === true)
    ) {
      setcan_update(true);
    } else {
      setcan_update(false);
    }
  }, [userpermission]);

  return (
    <>
      {(list_toggle === true ? data1 : data).length === 0 ? (
        <tr>
          <td>No Data Found</td>
        </tr>
      ) : (
        (list_toggle === true ? data1 : data).map((blog, index) => {

       console.log("blog----",blog)

          return (
            <tr
              key={index}
              style={{
                borderWidth: 1,
              }}
            >            
              <td>{blog?.employee_id} </td>
              <td>{blog?.firstname} {blog?.middlename} {blog?.lastname}</td>
              <td>{blog?.email}</td>
              <td>{toTitleCase(blog?.reporting_manager_firstname)} {toTitleCase(blog?.reporting_manager_middlename)} {toTitleCase(blog?.reporting_manager_lastname)}</td>
              <td>{blog?.home_branch_name}</td>
            </tr>
          );
        })
      )}
    </>
  );
};

export default DashboardUserData;
