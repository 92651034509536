import React from 'react'
import './NewDasboard.css'
import { FaUserAlt } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const TopCards = ({ width, backgroundcolor, header, navigateTo,cursor,total_user }) => {
    const navigate = useNavigate();


// all the states


    // Navigate to the route passed via props
    const handleNavigation = () => {
        navigate(navigateTo);
    };

    const formatDate = (date) => {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        let formattedDate = new Date(date).toLocaleDateString('en-US', options);
      
        const day = new Date(date).getDate();
        let suffix = 'th';
      
        if (day === 1 || day === 21 || day === 31) {
          suffix = 'st';
        } else if (day === 2 || day === 22) {
          suffix = 'nd';
        } else if (day === 3 || day === 23) {
          suffix = 'rd';
        }
      
        formattedDate = formattedDate.replace(
          /\d{1,2}/, 
          (match) => `${match}${suffix}`
        );
      
        return formattedDate.toUpperCase();
      };
      
      const todayDate = formatDate(new Date());
      
      
    

    return (
        <div className='topcards' style={{ width: width, background: backgroundcolor,cursor:cursor,header:header }}>
            <div className='cards-header'>
                <div className='icon-first'><FaUserAlt size={12} /></div>
                <div className='header-card'>{header}</div>
            </div>

            <div className='cards-second' style={{ fontSize: "12px" }}>{todayDate}</div>

            <div className='cards-third'>
                <span style={{ display: "block", fontSize: "22px", fontWeight: "bold" }}>{total_user}</span>
                <span style={{ display: "block", fontSize: "12px" }}>Increase by 40% from last quarter </span>
            </div>

            <div className='cards-four' onClick={handleNavigation}>
                <span style={{ color: "blue", textDecoration: "underline", fontSize: "12px" }}>View Details
                </span>
                <span>
                    <IoIosArrowForward size={12} color={'blue'} />
                </span>
            </div>
        </div>
    )
}

export default TopCards