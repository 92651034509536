import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDocuments: [], // Array of user documents
  userEmail: "", // Currently logged-in user's email
};

export const docVerSlice = createSlice({
  name: "userDocs",
  initialState,
  reducers: {
    setUserDocs: (state, action) => {
      // Check if user document already exists
      const existingUserDocIndex = state.userDocuments.findIndex(
        doc => doc.email === state.userEmail
      );
      
      // If user document exists, update it
      if (existingUserDocIndex !== -1) {
        state.userDocuments[existingUserDocIndex] = {
          ...state.userDocuments[existingUserDocIndex],
          ...action.payload
        };
      } else { // If user document doesn't exist, add a new one
        state.userDocuments.push({
          email: state.userEmail,
          ...action.payload
        });
      }
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    clearuserDocs: (state) => {
      state.userDocuments = [];
    }
  },
});

export const { setUserDocs, setUserEmail, clearuserDocs } = docVerSlice.actions;

export default docVerSlice.reducer;
