import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "./BirthdayModal.css";
import axios from "axios";
import { ServerAddress } from "../../../../constants/ServerAddress";
import { useSelector } from "react-redux";
import { EffectCards } from "swiper";
import toTitleCase from "../../../../lib/titleCase/TitleCase";

export default function BirthdayModal() {
  const accessToken = useSelector((state) => state.authentication.access_token);

  const [birthday_List, setbirthday_List] = useState([]);
  const get_birthday = () => {
    let final = [];

    axios
      .get(ServerAddress + `ems/get_user_birthday/?p=1`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        let pair = response.data.results;
        pair.forEach((item) => {
          final.push({
            Date: item.date_of_birth,
            profile: item.profile_pic,
            user_firstname: item.firstname,
            user_middlename: item.middlename,
            user_lastname: item.lastname,
            user_designation: item.designation_name,
          });
        });
        setbirthday_List(final);
      })
      .catch((error) => {
        console.log(`Error while in get data,${error}`);
      });
  };

  useEffect(() => {
    get_birthday();
  }, []);

  const today = new Date();
  const todayMonthDay =
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    today.getDate().toString().padStart(2, "0");

  let today_birthday = birthday_List.filter((item) => {
    if (item.Date !== null) {
      const itemMonthDay =
        item.Date.split("-")[1] + "-" + item.Date.split("-")[2];
      return itemMonthDay === todayMonthDay;
    }
    return false;
  });

  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        {today_birthday.map((bday, index) => {
          return (
            <SwiperSlide>
              <div className="card swiper-slide" key={index}>
                <div className="image-content">
                  <span className="overlay"></span>

                  <div className="card-image">
                    <img
                      src={bday.profile}
                      alt="Available"
                      className="card-img"
                    ></img>
                  </div>
                </div>

                <div className="card-content">
                  <h3 className="name">
                    {toTitleCase(bday.user_firstname)} {toTitleCase(bday.user_middlename)} {toTitleCase(bday.user_lastname)}
                  </h3>
                  <p className="description">{bday.Date}</p>
                  <p className="description">{toTitleCase(bday.user_designation)}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
