import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaExpandArrowsAlt, FaCrosshairs } from "react-icons/fa";
import { useNavigate } from "react-router";
import axios from "axios";
import { ServerAddress } from "../../constants/ServerAddress";
import { setUserDepartment } from "../../store/authentication/Authentication";
import useWindowDimensions from "./ScreenSize";
import BirthdayModal from "./timeLineSection/birthDay/BirthdayModal";
import { Button } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import DefaultDashboard from "./DefaultDashboard";
import ClientDashboard from "./dashboardTypes/ClientDashboard";
import CheckerDashboard from "./dashboardTypes/CheckerDashboard";
import DashboardTimelineSection from "./DashboardTimelineSection";
import NSearchInput from "../../components/formComponent/nsearchInput/NSearchInput";
import VmsDashboard from "./dashboardTypes/VmsDashboard";
import TripDashboard from "./dashboardTypes/TripDashboard";
import BillingDashboard from "./dashboardTypes/BillingDashboard";
import "./Dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authenticationSlice, setis_first_login } from "../../store/authentication/Authentication";
import toTitleCase from "../../lib/titleCase/TitleCase";
import { HiCheckBadge } from "react-icons/hi2";
import NewDasboard from "./NewDasboard";



const Dashboard = () => {
  const navigate = useNavigate();

  // Redux State
  const department = useSelector((state) => state.authentication.userdetails);
  const [dep_id, setdep_id] = useState("");
  const accessToken = useSelector((state) => state.authentication.access_token);
  const userdetails = useSelector((state) => state.authentication.userdetails);
  console.log('userdetails--', userdetails)
  const [is_first_login, setis_first_login] = useState(false);

  const dispatch = useDispatch();

  const today = new Date();

  // Extract the year, month, and day from the Date object
  const year = today.getFullYear();
  // JavaScript months are zero-based (0 for January)
  const month = today.getMonth() + 1;
  const day = today.getDate();

  // Extract the hours, minutes, and seconds from the Date object
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const seconds = today.getSeconds();

  // Format the date as desired (e.g., YYYY-MM-DD)
  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  // Format the time as desired (e.g., HH:MM:SS)
  const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;


  let timeArray = formattedTime.split(":");
  let hour = parseInt(timeArray[0]);

  let greeting = "";

  if (hour >= 6 && hour < 12) {
    greeting = "Good Morning ,";
} else if ((hour >= 12 && hour < 16) || (hour >= 0 && hour < 4)) {
    greeting = "Good Afternoon ,";
} else {
    greeting = "Good Evening ,";
}

const [counter, setcounter] = useState()

  const showOfferToastsucess = () => {
    toast(
      <div className="main_first">
        <div className="first">
          <div className="center">
            <HiCheckBadge size={25}
              style={{ color: 'green' }}
            />
          </div>
        </div>
        <div>
          <p>{greeting} {" "} {toTitleCase(userdetails.username)}</p>
        </div>

        <div>
          <p>Your Attendance Has Been Marked For Today </p>
        </div>

        <div className="last">

          <div className="last_first">
            <p>Date : {formattedDate}</p>
          </div>

          <div className="last_last">
            <p>Time : {formattedTime}</p>
          </div>

        </div>

      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        className: 'toast-message',
      }
    );
  };

  const getdepartment = () => {
    axios
      .get(ServerAddress + `ems/Get_Clockin_time/?search=&data=all&p=1&records=10&first_login=true&userwise=${'user_name'}&Attendance_type=${'Attendance_type'}&from_date=${'from_date'}&to_date=${'to_date'}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
      .then((response) => {
        let response_data = response?.data?.results;
        setis_first_login(response_data[0]?.is_first_login)
      })
      .catch((err) => {
        // alert(`Error Occur in Get , ${err}`);
      });
  };
  useEffect(() => {
    if(accessToken){
    getdepartment();
    }
  }, []);


  useEffect(() => {
    if (is_first_login === true && !localStorage.getItem('has_logged_in_before')) {
      showOfferToastsucess();
      localStorage.setItem('has_logged_in_before', true);
    }
  }, [is_first_login]);



  // useEffect(() => {
  //   let dep_id = String(department.priority - 1).padStart(2, "0");
  //   if (dep_id === "00") {
  //     setdep_id("01");
  //   } else {
  //     setdep_id(dep_id);
  //   }
  // }, [department]);

  // To get Screen Size
  const { width } = useWindowDimensions();

  // for dashborad type
  const [dashboard_type, setdashboard_type] = useState("Home");
  const [dashboard_type_list, setdashboard_type_list] = useState([
    "Home",
    "Client",
    "Maker-Checker",
    "VMS",
    "TRIP",
    "BILLING",
    "OERATIONAL",
  ]);

  const [dashboard_time_frame_list, setdashboard_time_frame_list] = useState([
    "Monthly",
    "Half Monthly",
    "Weekly",
  ]);
  const [dashboard_time_frame, setdashboard_time_frame] = useState("");

  // Modal funcation
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    // setmessage_error(false)
  };

  const [birthday_list, setbirthday_list] = useState([""]);


  
  return (
    <>
      <ToastContainer />
      {/* Birtday Modal */}
      <Modal show={show} onHide={handleClose} className="custom_modal">
        <Modal.Header closeButton>
          <Modal.Title
            style={{ fontFamily: "'Poppins', sans-serif", fontSize: "400" }}
          >
            {" "}
            Today's Birthdays
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#454545" }}>
          {birthday_list.length === 0 ? (
            <div
              className="birt_day"
              style={{ fontFamily: "Poppins sans-serif", fontSize: "400" }}
            >
              NO Birthdays Today
            </div>
          ) : (
            <>
              {birthday_list.map((item, idx) => {
                return <BirthdayModal />;
              })}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ fontFamily: "'Poppins', sans-serif", fontSize: "400" }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{
          display: "flex",
          Width: width,
          // Width: "100%",
          flexDirection: width > 800 ? "row" : "column",
          background: "white",

        }}
      >
        {/* For Chart */}
        <div
          className="custom-scrollbars__content"
          style={{
            display:"flex",
            flexDirection:"column",
            padding: "10px",
            flex: "0.75",
            overflowY: "scroll",
            maxHeight: "83vh",
            gap:"10px",
            // justifyContent: "space-between",
           }}
        >
          {/* filter for admin */}

          {userdetails?.is_superuser && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "blue",
                color: "white",
                padding: "4px",
                borderRadius: "12px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/dashboard/DashboardTypes/OrderDetails");
                }}
              >
                <FaExpandArrowsAlt />
              </div>

              <div className="responsive-div">
                <div
                  className="responsive-child"
                  onClick={() => {
                    navigate("/dashboard/DashboardTypes/BranchDailyDetails");
                  }}
                >
                  <FaCrosshairs />
                </div>
              </div>
             
              <div style={{background:"#DAD4B5",color:"black"}}>
                <select
                  onChange={(e) => {
                    setdashboard_type(e.target.value);
                  }}
                >
                  <option defaultValue={"Home"} value={"Home"}>
                    Home
                  </option>
                  <option value={"Maker-Checker"}>CMS</option>
                </select>
            
              </div>
            </div>
          )}

          {dashboard_type === "Home" ? (
            <>
              <NewDasboard greeting={greeting} setcounter={setcounter} counter={counter}/>
            </>
          ) : null}


          {dashboard_type === "Maker-Checker" ? (
            <>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "32px",
                  fontFamily: "Georgia",
                  display: "flex",
                  postion: "relative",
                  padding: "inherit",
                }}
              >
                Checker/Maker Dashboard
              </div>
              <CheckerDashboard />
            </>
          ) : null}

      
        </div>

        {/* For Timeline */}
        <div
          style={{
            flex: "0.25",
            Height: "83vh",
           }}
        >
          <DashboardTimelineSection show={show} setShow={setShow} holiday_data ={counter}/>
        </div>
      </div>

    </>
  );
};

export default Dashboard;