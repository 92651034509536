import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdNavigateNext,
} from "react-icons/md";
import { useSelector } from "react-redux";
import { setPageNumber } from "../../store/pagination/Pagination";
import { setNavToggle } from "../../store/dataList/DataList";
import {
  setFilterA,
  setFilterB,
  setFilterC,
  setFilterD,
  setFilterE,
  setFilterF,
  setFilterG,
  setFilterH,
  setFilterI,
  setFilterJ,
  setFilterK,
  setFilterL,
  setFilterM,
} from "../../store/filterValue/FilterValue";

const SideBar = () => {
  const dispatch = useDispatch();
  const [refresh, setrefresh] = useState(false);
  const navigation_list_o = useSelector(
    (state) => state.permissions.navigation_list,
  );
  const [navigation_lst, setnavigation_lst] = useState();
  let navigation_list_p = structuredClone(navigation_list_o);

console.log("navigation_lst",navigation_lst)


  useEffect(() => {
    setnavigation_lst(navigation_list_p);
  }, [navigation_list_o]);

  const [innerWidth, setinnerWidth] = useState();
  let wind_Width = window.innerWidth;
  useEffect(() => {
    setinnerWidth(wind_Width);
  }, [wind_Width]);
  // let navigation_list_p = [
  //   {
  //     id: 0,
  //     dropdown: "Dashboard",
  //     dropdownMenu: [
  //       ["Dashboard", "/dashboard"],
  //       // ["Eway_Bill Dashboard", "/dashboards_dashboards"],
  //     ],
  //     trigger: false,
  //   },
  //   {
  //     id: 10,
  //     dropdown: "EMS",
  //     dropdownMenu: [
  //       ["Login Details", "/ems/logindetails"],
  //       ["Users", "/Ems/User/Users"],
  //     ],
  //     trigger: false,
  //   },
  //   {
  //     id: 7,
  //     dropdown: "Masters",
  //     dropdownMenu: [["Commodities", "/master/commodities"]],
  //     trigger: false,
  //   },
  // ];

  const handlefun = () => {
    dispatch(setPageNumber(1));
    dispatch(setFilterA([]));
    dispatch(setFilterB([]));
    dispatch(setFilterC([]));
    dispatch(setFilterD([]));
    dispatch(setFilterE([]));
    dispatch(setFilterF([]));
    dispatch(setFilterG([]));
    dispatch(setFilterH([]));
    dispatch(setFilterI([]));
    dispatch(setFilterJ([]));
    dispatch(setFilterK([]));
    dispatch(setFilterL([]));
    dispatch(setFilterM([]));
  };

  return (
    <>
      <div className={innerWidth < 1200 ? "tog" : "vertical-menu"}>
        <div className="sidebar-conatiner navbar-brand-box">
          <div className="sidebar">
            {/* <div> */}
            <h4 className="text-light">
              <Link to="/dashboard">eTechCube</Link>
              {innerWidth < 1200 && (
                <span
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={() => dispatch(setNavToggle(true))}
                >
                  <MdNavigateNext />
                </span>
              )}
            </h4>

            {/* </div> */}
            <hr />
            <nav>
              {navigation_lst &&
                navigation_lst.map((n, indx) => {
                  return (
                    <div className="dropdown" key={n.id}>
                      <p
                        onClick={() => {
                          navigation_lst[indx].trigger =
                            !navigation_lst[indx].trigger;
                          setrefresh(!refresh);
                        }}
                      >
                        {/* <img src="./img/file.svg" alt="" /> */}
                        {n.dropdown}
                        <span>
                          {navigation_lst[indx].trigger ? (
                            <MdKeyboardArrowUp />
                          ) : (
                            <MdKeyboardArrowDown />
                          )}
                        </span>
                      </p>
                      <div
                        className="dropdownMenu"
                        id={navigation_lst[indx].trigger ? "active" : ""}
                        onClick={() => handlefun()}
                      >
                        {n.dropdownMenu.map((i) => (
                          <Link to={i[1]} key={i[0]}>
                            {i[0]}
                          </Link>
                        ))}
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
